.App_header {
    background-color: var(--body_background);    
    color: var(--text_color);                   
          
    z-index: 1000;

    left: 0px;

    right: 0px;

    top: 0px;                          
  position: fixed;
  width: 100%;
    display: flow-root;                           
    padding: 10px 20px;                           
    box-sizing: border-box;   
    -webkit-box-shadow: 0px 5px 5px -4px rgba(0,0,0,0.14);
    -moz-box-shadow: 0px 5px 5px -4px rgba(0,0,0,0.14);
    box-shadow: 0px 5px 5px -4px rgba(0,0,0,0.14);       
    user-select: none;            /* Standard syntax */
    -webkit-user-select: none;    /* For Safari and Chrome */
    -moz-user-select: none;       /* For Firefox */
    -ms-user-select: none;        /* For IE/Edge */
}
.App_header .left_div
{
    float: left;
    width: 100px;
    
    height: 50px; 
    
    position: relative;
}
.App_header .left_div .menu_img
{
float: left;
height: 35px;
width: 30px; 
margin:8px 10px 6px 0px; 
cursor: pointer;
filter: var(--icon-color);
}
.App_header .left_div .logo
{
    width: 100px;
    height: 50px;
    float: left;
    object-fit: contain;
    cursor: pointer;
    opacity: 0.9;
}
.App_header .center_body
{
    
    height: 45px;
    float: left;
  position: relative;
  margin:2px 1px 2px 50px;
}
.App_header .center_body .search {
    width: calc(100% - 40%);
    height: 45px;
   min-width: 400px;  
   margin:1px 1px 1px 1px;
    border: solid 1px var(--border_color);
    border-radius: 30px;
    display: flow-root;
    background-color: var(--layout_background);
     
}
.App_header .center_body .link_btn {
    text-decoration: none;
    float: left;
   line-height: 30px;
    font-weight:400;
    padding: 3px 15px;
    margin: 5px 10px 5px 0px; 
    cursor: pointer;
    font-size: var(--font_size_title);
    position: relative;
    transition: color 0.5s ease;
border-radius: 5px;
}
.App_header .center_body .link_btn:hover {
    transition: width 0.5s ease;     
 color: var(--theme_color);
background-color: var(--theme_lite_color);
}


/* Input field inside search */
.App_header .center_body .search .src_input {
    width: calc(100% - 75px);
    height: calc(100% - 7px);
    margin: auto;
    outline: none;
    padding: 0;
    border-radius: 20px;
    margin: 2px 10px;
    background-color: var(--layout_background);
    font-size: var(--font_size_title);
    border: none;
    text-transform: capitalize;
    float: left;
}

/* Change .search border color when .src_input is focused */
.App_header .center_body .search:focus-within {
    border: solid 2px var(--theme_color);   /* Border color on focus */
    margin:0px 0px 0px 0px;

}

.App_header .center_body .search .search_img
{
float: right;
height: 30px;
width: 30px;
 object-fit: contain;
margin: 7px 10px 7px 0px; 
cursor: pointer;
filter: var(--icon-color);
opacity: 0.8;
}
 
.App_header .right_div
{
    width:auto;
    height: 50px;
    float: right;
  
    position: relative;
     
}
.App_header .right_div .icon_img
{
float: left;
height: 30px;
width: 30px;
 object-fit: contain;
margin: 10px 0px 10px 30px; 
cursor: pointer;
filter: var(--icon-color);
}
.App_header .right_div .login_settings
{
float: left;
height: 50px;
width: 50px;
 
margin: 0px 0px 0px 30px; 
cursor: pointer;
border-radius: 50%;
}
.App_header .right_div .login_button
{
float: left;
height: 40px;
min-width: 50px;
 line-height: 40px;
 font-size: var(--font_size_title);
 font-weight: 600;
 border: solid 1px var(--theme_color);
 background-color: var(--theme_color);
 text-align: center;
 color: #fff;
margin: 5px 0px 5px 20px; 
cursor: pointer;
border-radius: 5px;

padding: 0px 10px;
}

@media only screen and (max-width: 600px) {
    .App_header .center_body
    {
        display: none;
    }
    .App_header {
        background-color: var(--body_background);    
        color: var(--text_color);                  
            
        z-index: 1000;

        left: 0px;
    
        right: 0px;
    
        top: 0px;                                
      position: fixed;
      width: 100%;
        display: flow-root;                           
        padding:5px 5px;                           
        box-sizing: border-box;   
        -webkit-box-shadow: 0px 5px 5px -4px rgba(0,0,0,0.14);
        -moz-box-shadow: 0px 5px 5px -4px rgba(0,0,0,0.14);
        box-shadow: 0px 5px 5px -4px rgba(0,0,0,0.14);       
    }
    .App_header .left_div
    {
        float: left;
        width: 150px;
        
        height: 50px; 
        
        position: relative;
    }
    .App_header .left_div .menu_img
    {
    float: left;
    height: 35px;
    width: 35px; 
    margin:8px 10px 6px 0px; 
    cursor: pointer;
    filter: var(--icon-color);
    }
    .App_header .left_div .logo
    {
        width: 80px;
        height: 50px;
        float: left;
        object-fit: contain;
        cursor: pointer;
        opacity: 0.9;
    }
    .App_header .right_div .icon_img
{
float: left;
height: 30px;
width: 30px;
 object-fit: contain;
margin: 10px 0px 10px 20px; 
cursor: pointer;
filter: var(--icon-color);
}
.App_header .right_div .login_settings
{
float: left;
height: 40px;
width: 40px;
 
margin: 5px 0px 5px 20px; 
cursor: pointer;
 
}
  }


