.app_home_footer
{ user-select: none;            /* Standard syntax */
    -webkit-user-select: none;    /* For Safari and Chrome */
    -moz-user-select: none;       /* For Firefox */
    -ms-user-select: none;        /* For IE/Edge */
    max-width: 100%;
    min-height: 60px;
 
background-color: var(--layout_background);
}

.tearms_body
{ user-select: none;            /* Standard syntax */
    -webkit-user-select: none;    /* For Safari and Chrome */
    -moz-user-select: none;       /* For Firefox */
    -ms-user-select: none;        /* For IE/Edge */
    max-width: 100%;
    display: flow-root;
    min-height: 30px;
    padding: 10px 20px;
 border-top: solid 1px var(--border_color);
 background-color: var(--layout_background);
}
.tearms_body .compny
{float: left;
    text-decoration: none;
    line-height: 20px;
    margin: 0px 0px 0px 0px;
    font-weight: 600;
   
}
.tearms_body .tearms_links
{float: left;
    text-decoration: none;
    cursor: pointer;
    font-weight: 400;   
    line-height: 20px;
    margin: 0px 0px 0px 20px;
}
.tearms_body .tearms_links:hover{
    text-decoration: underline;
}
/* Bottom border effect */
/* .tearms_body .tearms_links::after {
    content: '';
    position: absolute;
    border-radius: 10px;
    left: 0;
    bottom: 0;
    width: 0;                                
    height: 3px;
    background-color: var(--theme_color);     
    transition: width 0.3s ease;              
} 
.tearms_body .tearms_links:hover::after {
    background-color: var(--theme_color);
    width: 100%;    
    
} */