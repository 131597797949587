:root{
  --theme_color:#EE4036;
  --theme_lite_color:#fce6e4;
  --body_background:white;
  --layout_background:#eee;
  --footer_background:rgb(46, 55, 66);
  --body_color:black;
  --link_color:navy;
  --text_color:#000000;
  --border_color:#ccc;
  --font_size_title:16px;
  --font_size_normal:14px;
  --font_size_info:10px;
  --icon-color: brightness(0) invert(0);
}
[data-theme="dark"]{
  --theme_color:#EE4036;
  --theme_lite_color:#fce6e4;
  --body_background:#1F1F1F;
  --footer_background:rgb(46, 55, 66);
  --layout_background:rgb(7, 1, 1)1F;
  --body_color:white;
  --link_color:rgb(8, 132, 180);
  --text_color:white;
  --border_color:#222222;
  --font_size_title:16px;
  --font_size_normal:14px;
  --font_size_info:10px;
  --icon_color:filter: brightness(0) invert(1); 
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--font_size_normal);
  font-weight: 300;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
