
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);  /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Popup content styling */
  .popup-content {
    background: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
    width: 400px;
    max-width: 90%;
    position: relative;
  }
  
  /* Close button styling */
  .close-btn {
    position: absolute;
    top: 10px;
    right: 15px;
    background: transparent;
    border: none;
    font-size: 18px;
    cursor: pointer;
  }
  
  .close-btn:hover {
    color: red;
  }
  .popup-content {
    animation: fadeIn 0.1s ease-in-out;
  }
  @keyframes fadeIn {
    30% {
      opacity: 0;
      transform: scale(0.9);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
    
  }
  
  