
  .login_popup-overlay {
    position: fixed;
    top: 60px;
  right: 10px;
    width: auto;
    height: auto;
   
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    user-select: none;            /* Standard syntax */
    -webkit-user-select: none;    /* For Safari and Chrome */
    -moz-user-select: none;       /* For Firefox */
    -ms-user-select: none;        /* For IE/Edge */
  }
  
  /* Popup content styling */
  .login_popup-content {
    background: var(--body_background);
    padding:5px 20px;
    display: flow-root;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
    ;
    max-width: 90%;
    position: relative;
  }
 
  .login_popup-content .user_name
  {
    font-weight: 400;
    margin: 5px 10px;
    text-transform: capitalize;
  }
  .login_popup-content .boder_box
  {
    padding: 10px 10px;
    background-color:var(--layout_background) ;
    margin: 1px 0px;
    border-radius: 5px;
 
  }
  .login_popup-content .boder_box.cur
  {    cursor: pointer;
  }
  .login_popup-content .boder_box.cur:hover {
    opacity: 0.6;
  }
  .login_popup-content .boder_box:active {
     
    opacity: 1;
  }
  .login_popup-content .logout_button
  {
    background-color: var(--body_background);
    border: solid 1px var(--border_color);
    padding: 8px 20px;
    width: 60px;
    font-size: var(--font_size_title);
    font-weight: 600;
    text-align: center;
    border-radius: 5px;
    margin: 10px 0px;
    cursor: pointer;
  }
  .login_popup-content .logout_button:hover
  {
    opacity: 0.6;
  }
  .login_popup-content .logout_button:active
  {
    opacity: 1;
  }
  .login_popup-content .login_button
  {
    padding: 10px 5px;
    margin: 0px 10px;
    cursor: pointer;
    display:flex;

    border-radius: 20px;
  }
  .login_popup-content .login_button .googleicon-pic
  {
    width: 30px;
    height: 30px;
    object-fit: contain;
    margin: 0px 0px;
  }
  .login_popup-content .login_button .google_name
  {
    font-size: var(--font_size_title);
    font-weight: 500;
    line-height: 30px;
    margin: 0px 10px 0px 10px;
  }
  .profile-pic {
    width: 100px;
    height: 100px;
    border-radius: 50%;
 
  }
  /* Close button styling */
  .login_close-btn {
    position: absolute;
    top: 15px;
    right: 10px;
    padding: 0px 1px 0px 10px;
    line-height: 30px;
    background: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  
  .login_close-btn:hover {
    color: red;
  }
  .login_popup-content {
    animation: fadeIn 0.2s ease-in-out;
  }
  @keyframes fadeIn {
    30% {
      opacity: 0;
      transform: scale(0.9);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
    
  }
  
  