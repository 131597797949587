.App {
  display: flow-root;
}
 
.app_header_height{
  height:80px;
  clear: both;
}
 
.container_port
{padding: 10px;
  min-height:calc(100vh - 130px);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}



.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
